export const Detail = [
  {
    id: 1,
    category: "Engine Shoutdown/Chocks On",
    critical: true,
    duration: "0.2",
    groundTeam: "Ground",
    attention: false,
    startTimeInMinutes: "0",
    progressInMinutes: "9",
    endTimeInMinutes: "10",
    comment: [],
    details: []
  },
  {
    id: 2,
    category: "Position Passenger Bridge/Chocks On",
    critical: true,
    duration: "9.7",
    groundTeam: "Team_A",
    attention: false,
    startTimeInMinutes: "5",
    progressInMinutes: "11",
    endTimeInMinutes: "11",
    comment: [{
      id: 1,
      title: "Ramp manager",
      description: "A short text that enrtered by the TR operators",
    }],
    details: []
  },
  {
    id: 3,
    category: "Deplane Passengers",
    critical: true,
    duration: "10.1",
    groundTeam: "Ground",
    attention: true,
    startTimeInMinutes: "8",
    progressInMinutes: "34",
    endTimeInMinutes: "30",
    comment: [{
      id: 14,
      title: "Ramp manager",
      description: "A short text that enrtered by the TR operators",
    },
      {
        id: 42,
        title: "Ramp manager",
        description: "A short text that enrtered by the TR operators",
      },
      {
        id: 34,
        title: "Ramp manager",
        description: "A short text that enrtered by the TR operators",
      }
    ],
    details: []
  },
  {
    id: 4,
    category: "Service Cabin",
    critical: true,
    duration: "10.4",
    groundTeam: "Team_A",
    attention: false,
    startTimeInMinutes: "19",
    progressInMinutes: "38",
    endTimeInMinutes: "38",
    comment: [],
    details: []
  },
  {
    id: 5,
    category: "Service Forward Gallery",
    critical: false,
    duration: "13.3",
    groundTeam: "Team_A",
    attention: false,
    startTimeInMinutes: "30",
    progressInMinutes: "42",
    endTimeInMinutes: "42",
    comment: [],
    details: []
  },
  {
    id: 6,
    category: "Upload Compartment",
    critical: false,
    duration: "9.2",
    groundTeam: "Team_B",
    attention: false,
    startTimeInMinutes: "11",
    progressInMinutes: "41",
    endTimeInMinutes: "40",
    comment: [],
    details: [
      {
        id: 21,
        title: "Fuel Vehicle On Stand",
        duration: "6.2",
        groundTeam: "Team_B",
        status: "green",
        startTimeInMinutes: "13",
      },
      {
        id: 22,
        title: "Fueling Begin",
        duration: "7.2",
        groundTeam: "Team_B",
        status: "blue",
        startTimeInMinutes: "24",
      },
      {
        id: 23,
        title: "Fueling End",
        duration: "10.2",
        groundTeam: "Team_B",
        status: "blue",
        startTimeInMinutes: "32",
      },
      {
        id: 24,
        title: "Fuel Vehicle Off Stand",
        duration: "10.2",
        groundTeam: "Team_B",
        status: "red",
        startTimeInMinutes: "48",
      }]
  },
  {
    id: 7,
    category: "Fuel Airplane",
    critical: true,
    duration: "",
    groundTeam: "Ground",
    attention: false,
    startTimeInMinutes: "46",
    progressInMinutes: "41",
    endTimeInMinutes: "58",
    comment: [],
    details: []
  },
  {
    id: 8,
    category: "Load Compartment",
    critical: true,
    duration: "",
    groundTeam: "Ground",
    attention: false,
    startTimeInMinutes: "42",
    progressInMinutes: "41",
    endTimeInMinutes: "50",
    comment: [],
    details: []
  },
  {
    id: 9,
    category: "Service Lavatories",
    critical: true,
    duration: "",
    groundTeam: "Ground",
    attention: false,
    startTimeInMinutes: "55",
    progressInMinutes: "41",
    endTimeInMinutes: "60",
    comment: [],
    details: []
  },
  {
    id: 10,
    category: "Service Potable Water",
    critical: true,
    duration: "1.0",
    groundTeam: "Ground",
    attention: false,
    startTimeInMinutes: "51",
    progressInMinutes: "41",
    endTimeInMinutes: "60",
    comment: [],
    details: []
  }
]
