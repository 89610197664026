import React, { useState } from 'react'
import './Sidebar.scss'
import { Link } from 'react-router-dom'
import SidebarItem from './SidebarItem'
import userLogo from '../../../assets/image/userLogo.png'
import {SidebarLine } from '../../../assets/img-svg/all-icons'


export default function Sidebar() {
  const [items, setItems] = useState([
    { id: 1, label: 'Process', path: '/processes', icon: 'repeat', active: false },
    { id: 2, label: 'Assets', path: '/assets', icon: 'assets', active: false },
    { id: 3, label: 'Settings', path: '/settings', icon: 'settings', active: false },
  ])

  const updateSidebar = (index) => {
    setItems((prev) => prev.map(item => {
      if (item.id !== index) {
        item.active = false
      } else {
        item.active = true
      }
      return item
    }))
  }

  return (
    <aside className='sidebar'>
      <Link to='/' className='sidebar-logo'>
          <SidebarLine width="18px" className='sidebar-list-item__img' />
      </Link>

      <div className="sidebar-menu">
        <ul className='sidebar-list'>
          {items.map(item => <SidebarItem key={item.id} {...item} updateSidebar={updateSidebar} />)}
        </ul>
      </div>
      <div className="sidebar-user-box">
        <div className="sidebar-user-box-img__border">
          <img src={userLogo} alt=""/>
        </div>
      </div>
    </aside>
  )
}
