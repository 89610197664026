import React, { useState } from 'react'
import './Filter.scss'
import { MapPin, Close, Search, Plus, All, Airport, Airlines, DeparturingFlight, Apply } from '../../../assets/img-svg/all-icons'
import { MyCheckbox } from '../MyCheckbox/MyCheckbox'

const Filter = ({ updateFilter, stepOne, updateFilterState }) => {
  const [openFilter, setOpenFilter] = useState(false)

  const toggleFilter = () => {
    setOpenFilter(prev => (prev = !openFilter))
  }

  return (

    <>
      <div className="filter-group">
        <div className="filter-tag">
          <span>
            <MapPin width="17px" className="tag-icon" />
          </span>
          <span className="tag-key">Airport: </span>
          <span className="tag-value">Gate, Location</span>
          <span>
            <Close width="18px" className="tag-close" />
          </span>
        </div>
        <div className="filter-tag">
          <span className="tag-value">Ben Gurion Airport</span>
          <span>
            <Close width="18px" className="tag-close" />
          </span>
        </div>
      </div>
      <div className="filter-search">
        <span>
          <Search width="17px" className="filter-search__icon" />
        </span>
        <input
          type="text"
          name="search"
          id="search"
          className="filter-search__input"
          placeholder="Search"
        />
      </div>
      <div className="add-filter">
        <div className="add-filter__box" onClick={toggleFilter}>
          <div className="add-filter-icon-wrap">
            <Plus width="36px" />
          </div>
          <span className="add-filter-title">Add filter</span>
        </div>
        {openFilter && (<div className="add-step">
          {stepOne &&
            stepOne.map(item => (
              item.display && <Step key={item.id} item={item} updateFilter={updateFilter} toggleFilter={toggleFilter} updateFilterState={updateFilterState} />
            ))}
        </div>)}
      </div>
    </>

  )
}

export default Filter

const Step = ({ item, updateFilter, toggleFilter, updateFilterState }) => {
  return (
    <div className="step" key={item.id}>
      <div className="step-item" onClick={(e) => updateFilter(e, item.id)}>
        {item.icon === "all" && <All width="17px" />}
        {item.icon === "airport" && <Airport width="17px" />}
        {item.icon === "airline" && <Airlines width="17px" />}
        {item.icon === "flight" && <DeparturingFlight width="12px" />}
        <span className="step__title">{item.name}</span>
      </div>
      {item.isActive && <CheckGroup items={item.children} />}
      {item.isActive && (
        <div className="step-apply" onClick={() => {
          toggleFilter()
          updateFilterState()
        }}>
          <Apply width="15px" />
          <span className="step-apply__title">Apply</span>
        </div>
      )}
    </div>
  )
}

const CheckGroup = ({ items }) => {
  return (
    <div className="step__option">
      {items.map(child => (
        <MyCheckbox
          key={`${child.id}`}
          label={child.label}
          name={child.label}
          id={`${child.id}`}
        />
      ))}
    </div>
  )
}
