import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from '../pages/Home/Home'
import Sidebar from '../ui/Sidebar/Sidebar';
import Process from '../pages/Process/Process'
import ProcessShift from '../pages/ProcessShift/ProcessShift'
import Main from '../ui/Main/Main'
import "./App.scss"
import { FlightTracker } from '../pages/FlightTracker/FlightTracker'
import { Login } from '../pages/Login/Login'

const App = () => {
  const [test, setTest]= useState(true)
  const ClickViewLoginPage = () => {
    setTest(false)
  }

  return (
    <div className='app'>
      {test ?
        <div className='container-fluid'>
          <Router>
            <Sidebar />
            <Main>
              <Switch>
                <Route path='/' exact
                       render={
                  () => <Home test={test} ClickViewLoginPage={ClickViewLoginPage} />
                } />
                <Route path='/processes' component={Process} />
                <Route path='/processes-shift' component={ProcessShift} />
                <Route path='/flight-tracker' component={FlightTracker} />
              </Switch>
            </Main>
          </Router>
        </div>

        :

        <Router>
          <Switch>
            <Route path='/login' component={Login} />
          </Switch>
        </Router>

      }


    </div>
  )
}
export default App
