import React from 'react'
import { LoginIcon, Logo } from '../../../assets/img-svg/all-icons'
import "./Login.scss"

export const Login = () => {

  return (
    <div className="login__bg login-box__position">
      <div className="login">
        <div className="login-logo-box">
          <Logo width="64px" />
        </div>
        <div className="login-content">
          <p className="login-content-heading">Sign in to continue</p>
          <form action="" className="login-content-form">
            <input type="text" placeholder="Email or username" className="login-content-form-input" />
            <input type="text" placeholder="Password" className="login-content-form-input" />
            <div className="login-content-form-button pointer hover-blue-button">
              <LoginIcon width="19px" />
              <span className="login-content-form-button__text ">Login now</span>
            </div>
            <div className="login-content-form-forgot pointer">
              <span>Forgot Your Password?</span>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}


