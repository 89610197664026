import React from 'react'
import { Link } from 'react-router-dom'
import { Repeat, Settings, Assets} from '../../../assets/img-svg/all-icons'

export default function SidebarItem({ id, icon, label, active, path, updateSidebar }) {
  const style = active ? 'sidebar-list-item sidebar-list-item--active' : 'sidebar-list-item'

  return (
    <li className={style} onClick={() => updateSidebar(id)}>
      <Link to={path}>
        {(icon === 'repeat') && (<Repeat width="17px" className='sidebar-list-item__img' />)}
        {(icon === 'settings') && (<Settings width="20px" className='sidebar-list-item__img' />)}

        {(icon === 'assets') && (<Assets width="20px" className='sidebar-list-item__img' />)}
        <p className='sidebar-list-item__label'>
          {label}
        </p>
      </Link>
    </li>
  )
}
