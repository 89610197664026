import React, { useState } from 'react'
import "./DetailTable.scss"
import { Detail } from './Data'
import { DetailTableItem } from './DetailTableItem/DetailTableItem'
import { TimeLine } from '../../../ui/TimeLine/TimeLine'


export const DetailTable = ({active}) => {
  const [detail, setDetail] = useState(Detail)

  const detailTableStyleLine = !active ? "not-active-style" : " "

  return (
    <div className={`airplane-detail-table table-max__width ${detailTableStyleLine}`}>

      <TimeLine maxWidthTable={".airplane-detail-table-content"} stepInMinuts={true}/>

      <div className="airplane-detail-table-header">
        <div className="airplane-detail-table-header-title">
          <span className="airplane-detail-table-header-item">Category</span>
          <span className="airplane-detail-table-header-item">Critical</span>
          <span className="airplane-detail-table-header-item">Duration</span>
          <span className="airplane-detail-table-header-item">Ground Handling Team</span>
        </div>
        <div className="airplane-detail-table-header-timeline line top">
          <span>PDO</span>
          <span>CDO</span>
          <span>Empty</span>
          <span>Cargo Done</span>
        </div>
      </div>
      <div className="airplane-detail-table-content scroll-bar__style" >
        {detail.map( item => {
           return (
             <DetailTableItem key={item.id} {...item}/>
           )
        })}
      </div>
      <div className="airplane-detail-table-box-time">
        <div className="airplane-detail-table-header-title">
          <span className="airplane-detail-table-header-item"> </span>
          <span className="airplane-detail-table-header-item"> </span>
          <span className="airplane-detail-table-header-item"> </span>
          <span className="airplane-detail-table-header-item"> </span>
        </div>
        <div className="airplane-detail-table-header-timeline line bottom">
          <span className="airplane-detail-table-header-time">0</span>
          <span className="airplane-detail-table-header-time">10</span>
          <span className="airplane-detail-table-header-time">20</span>
          <span className="airplane-detail-table-header-time">30</span>
          <span className="airplane-detail-table-header-time">40</span>
          <span className="airplane-detail-table-header-time">50</span>
          <span className="airplane-detail-table-header-time">60</span>
        </div>
      </div>
    </div>
  )
}
