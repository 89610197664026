import React, { useState } from 'react'
import './FilterHeader.scss'

import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import Filter from '../Filter/Filter'

const FilterHeader = ({ updateFilter, stepOne, updateFilterState }) => {
  const [date, setDate] = useState([new Date(), new Date()])

  const onChange = date => setDate(date)

  return (
    <>
      <nav className="process-header-action">

          <div className="datepicker">
            <span className="datepicker__label">Date:</span>
            <DateRangePicker
              onChange={onChange}
              value={date}
            />
          </div>
        <Filter updateFilter={updateFilter} stepOne={stepOne} updateFilterState={updateFilterState} />

      </nav>
    </>
  )
}

export default FilterHeader
