import React from 'react'
import { Close, Plus } from '../../../../../../assets/img-svg/all-icons'
import "./DetailItemComments.scss"

export const DetailItemComments = ({ comment, commitAdd, onCommitClose }) => {
  const commitExtraStyle = !commitAdd ? "" : "append" ;

  return (
    <div className={`comment-box ${commitExtraStyle}`}>

      {!commitAdd ?
        <>
          <div className="comment-box-content">
            {comment.map(item => {
              return (
                <div key={item.id} className="comment-box-content-row">
                  <p className="comment-box-content-row-title">{item.title}</p>
                  <p className="comment-box-content-row-desc">{item.description}</p>
                  <div className="line-border"> </div>
                </div>
              )
            })}
          </div>
          <div className="comment-box-add comment-box-icon pointer">
            <Plus width="36px" />
          </div>
        </>
        :
        <div className="comment-box-append">
          <p className="comment-box-append-title">Add comment</p>
          <textarea className="comment-box-append-textArea" placeholder="As both private and commercial air travel continues to rise in the U.S., the need for aircraft.">
              </textarea>
          <div className="comment-box-append-button-group">
            <div className="button-cancel pointer">
              <span>Cancel</span>
            </div>
            <div className="button-add pointer">
              <span>Add</span>
            </div>
          </div>
        </div>
      }

      <div className="comment-box-close comment-box-icon pointer " onClick={onCommitClose}>
        <Close width="16px"/>
      </div>
    </div>
  )
}
