import React from 'react'

const TableRow = (
  { rowId,
    flight,
    airlines,
    destination,
    timeStatus,
    updateTableItem,
    onClickDetail
  }) => {

  return (
    <div className='table-content-columns width-for-sticky-scroll' >

      <div className="table-content-columns title">
        <span className='table-content-columns__width'>{flight}</span>
        <span className='table-content-columns__width'>{airlines}</span>
        <span className='table-content-columns__width'>{destination}</span>
      </div>
      <div className="table-content-columns time">
        {timeStatus.map(item => {
          return (
            <TableRowStatus key={item.id}
                            {...item}
                            onClickDetail={onClickDetail}
                            updateTableItem={updateTableItem}
                            rowId={rowId}
            />
          )
        })}
      </div>

    </div>
  )
}
export {TableRow}

const TableRowStatus = (
  {id,
    arrivalTime,
    active,
    status,
    airplaneDetails,
    updateTableItem,
    onClickDetail,
    rowId,
  }) => {

  let mrLeftStyle;
  if (arrivalTime) {
    const arrivalMinutes = Number(arrivalTime.slice(3))
    mrLeftStyle = arrivalMinutes * 100 / 60
  }

  //
  const empty = <div className={`table-content-columns-status ${status} `}> </div>;

  const isActive = active ? 'active' : ""

  const inAirport = <div className={`table-content-columns-status pointer `}
                         onClick={ () => {
                           onClickDetail(airplaneDetails)
                           updateTableItem(id, rowId)
                         } }
                         style={{marginLeft: `${mrLeftStyle}%`}}>
    <div className={`table-content-columns-status__progress ${status} ${isActive} progress_line` } > </div>
  </div>;

  const airplaneIn = (status === "empty") ? empty :  inAirport ;  // status == Bg color

  return (
    <span className='table-content-columns__width'>
    {airplaneIn}
    </span>
  )
}
