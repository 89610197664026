import React, { useState } from 'react'
import { CloseRed} from '../../../assets/img-svg/all-icons'
import { SearchHeader } from '../SearchHeader/SearchHeader'
import "./AirplaneDetail.scss"
import { Link } from 'react-router-dom'

export const AirplaneDetail = (
  {airline,
    flight,
    airplaneType,
    gate,
    groundHandling,
    destination,
    weather,
    AIBT,
    EOBT,
    EOBTplus,
    detail,
    detailActive,
    onDetailClose,
    onClickDetail,
    updateTableItem,
    searchVisible
  }) => {
  const [activeSearch, setActiveSeach] = useState(false)

  const onActiveSearch = (e) => {

    setActiveSeach ( true)
    e.stopPropagation()
  }

  const activeDetail = !detailActive ? "d-none" : "airplane-detail"
  const activeSearchClass = activeSearch ? "active" : ""
  return (
    <div className={activeDetail}>
      <div className="main airplane">
        <div className="table-content table-max__width">
          <div className="airplane-detail-header ">
            <div className={`airplane-detail-header-content ${activeSearchClass}`}>
              <div className="airplane-detail-header__column">
                <span className="airplane-detail-header__title" >
                  Airline
                </span>
                <span className="airplane-detail-header__text" >
                  {airline}
                </span>
              </div>
              <div className="airplane-detail-header__column">
                <span className="airplane-detail-header__title" >
                  Flight
                </span>
                <span className="airplane-detail-header__text" >
                  {flight}
                </span>
              </div>
              <div className="airplane-detail-header__column">
                <span className="airplane-detail-header__title" >
                  Airplane type
                </span>
                <span className="airplane-detail-header__text" >
                  {airplaneType}
                </span>
              </div>
              <div className="airplane-detail-header__column">
                <span className="airplane-detail-header__title" >
                  Gate
                </span>
                <span className="airplane-detail-header__text" >
                  {gate}
                </span>
              </div>
              <div className="airplane-detail-header__column">
                <span className="airplane-detail-header__title" >
                  Ground handling
                </span>
                <span className="airplane-detail-header__text" >
                  {groundHandling}
                </span>
              </div>
              <div className="airplane-detail-header__column">
                <span className="airplane-detail-header__title" >
                  Destination
                </span>
                <span className="airplane-detail-header__text" >
                  {destination}
                </span>
              </div>
              <div className="airplane-detail-header__column">
                <span className="airplane-detail-header__title" >
                  Weather
                </span>
                <span className="airplane-detail-header__text" >
                  {weather}
                </span>
              </div>
              <div className="airplane-detail-header__column">
                <span className="airplane-detail-header__title" >
                  AIBT
                </span>
                <span className="airplane-detail-header__text" >
                  {AIBT}
                </span>
              </div>
              <div className="airplane-detail-header__column">
                <span className="airplane-detail-header__title" >
                  EOBT
                </span>
                <span className="airplane-detail-header__text" >
                  {EOBT} {(EOBTplus && EOBTplus.length > 2) ? ' /' : null }
                  <span className="airplane-detail-header__text_red">
                    / +0.5
                </span>
                </span>
              </div>
            </div>
            <div>
              { searchVisible ?
                <SearchHeader activeSearch={activeSearch} onActiveSearch={onActiveSearch}/>
                : <div className="airplane-detail-header-button-group">
                  <Link to='/flight-tracker' >
                  <div className="airplane-detail-view pointer">
                      <span>View</span>
                    </div>
                  </Link>
                    
                    <CloseRed width="36px" className="airplane-detail-close pointer "
                            onClick={() => {
                              onClickDetail()
                              onDetailClose()
                              updateTableItem(false)
                            }}/>
                  </div>
              }
            </div>
          </div>
          <div className="airplane-detail-content">

            {detail && detail.map( (item) => {
              return (
                <AirplaneDetailItem key={item.id} {...item} />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const AirplaneDetailItem = (
  {title,
    value,
    max,
    maxPlus,
    active
  }) => {

  let val = Number(value);
  let maxVal = Number(max)
  let progressPercent = val * 100 / maxVal;
  let visibleValue = (0 !== val) && value               // used in HTML
  let visibleMaxPlus = (maxPlus === undefined) ? "" : `/+${maxPlus}`

  let progressLine;
  let bgLine;
  let visibleVal;

  if (0 === val) {
    bgLine = "airplane-detail-content-line"
  }else if (val <= maxVal) {
    bgLine = "airplane-detail-content-line green"
  } else {
    bgLine = "airplane-detail-content-line red"
  }

  visibleVal = (val <= maxVal) ? "airplane-detail-content-line__val green": "airplane-detail-content-line__val red";

  if (!active) {
    progressLine = (val <= maxVal) ? "airplane-detail-content-line__progress green" : "airplane-detail-content-line__progress red";
  } else {
    progressLine = (val <= maxVal) ? "airplane-detail-content-line__progress__active green" : "airplane-detail-content-line__progress__active red"
  }

  return (
    <div className="airplane-detail-content__column">
      {title}
      <div className={bgLine}>
        <div className={progressLine} style={{width: `${progressPercent}%` }}> </div>
      </div>
      <div className="airplane-detail-content__value">
        <div className={visibleVal} > <b>{visibleValue}</b></div>
        <div>exp. <b>{max}{visibleMaxPlus}</b></div>
      </div>
    </div>
  )
}
