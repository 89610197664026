import React, { useEffect, useState } from 'react'
import './TableContent.scss'
import {TableRow} from './TableRow'
import {AirplaneDetail} from '../AirplaneDetail/AirplaneDetail'
import { SelectDownBlue } from '../../../assets/img-svg/all-icons'
import { TimeLine } from '../TimeLine/TimeLine'

const TableContent = (
  {airlinesItem,
    updateTableItem,
    extraStyle,
    timeLineVisible}) => {

  const [detailActive, setDetailActive]= useState(false)
  const [detail, setDetail] = useState( {})

  const onClickDetail = (details) => {
    if ( details !== detail ){
      setDetail(details)
      setDetailActive(() => true)
    } else {
      setDetail({})
      setDetailActive(() => false)
    }
  }

  const onDetailClose = () => {
    setDetailActive( () => false)
  }
  useEffect( () => {
    let a = document.querySelectorAll(".width-for-sticky-scroll")
    console.log(a.scrollWidth)

    Array.prototype.map.call(a, (a)=> {

      a.style.width = `${a.scrollWidth}px`
    });
  })
  return (
    <>
      <div className={`table-content table-max__width scroll-bar__style ${extraStyle}`}>
        {timeLineVisible && <TimeLine maxWidthTable={".table-content"} /> }

      <div className='table-content-columns width-for-sticky-scroll table-content-header'>
        <div className="table-content-columns title">
          <span className="table-content-columns__width">
            Flight
              <SelectDownBlue width='12px' className="table-content-columns__arrow" height='6px'/>
          </span>
            <span className="table-content-columns__width">
            Airlines
          </span>
            <span className="table-content-columns__width">
            Destination
          </span>
        </div>

        <div className="table-content-columns time">
          <span className="table-content-columns__width">
            00:00
          </span>
          <span className="table-content-columns__width">
            01:00
          </span>
          <span className="table-content-columns__width">
            02:00
          </span>
          <span className="table-content-columns__width">
            03:00
          </span>
          <span className="table-content-columns__width">
            04:00
          </span>
          <span className="table-content-columns__width">
            05:00
          </span>
          <span className="table-content-columns__width">
            06:00
          </span>
          <span className="table-content-columns__width">
            07:00
          </span>
          <span className="table-content-columns__width">
            08:00
          </span>
          <span className="table-content-columns__width">
            09:00
          </span>
          <span className="table-content-columns__width">
            10:00
          </span>
          <span className="table-content-columns__width">
            11:00
          </span>
          <span className="table-content-columns__width">
            12:00
          </span>
          <span className="table-content-columns__width">
            13:00
          </span>
          <span className="table-content-columns__width">
            14:00
          </span>
          <span className="table-content-columns__width">
            15:00
          </span>
          <span className="table-content-columns__width">
            16:00
          </span>
          <span className="table-content-columns__width">
            17:00
          </span>
          <span className="table-content-columns__width">
            18:00
          </span>
          <span className="table-content-columns__width">
            19:00
          </span>
          <span className="table-content-columns__width">
            20:00
          </span>
          <span className="table-content-columns__width">
            21:00
          </span>
          <span className="table-content-columns__width">
            22:00
          </span>
          <span className="table-content-columns__width">
            23:00
          </span>
        </div>

      </div>
        <div className='table-content-row'>
          {airlinesItem.map(item => {
            return (
              <TableRow key={item.id}
                        rowId={item.id}
                        {...item}
                        onClickDetail={onClickDetail}
                        updateTableItem={updateTableItem} />
            )
          })}
        </div>
      </div>
      <AirplaneDetail {...detail}
                      detailActive={detailActive}
                      onClickDetail={onClickDetail}
                      onDetailClose={onDetailClose}
                      updateTableItem={updateTableItem}
      />
    </>
  )
}
export {TableContent}

