export const FilterData = [
  {
    id: 1,
    isActive: false,
    display: true,
    name: 'All',
    icon: 'all',
    children: [
      {
        id: 1,
        label: 'Terminal'
      },
      {
        id: 2,
        label: 'Gates'
      },
      {
        id: 3,
        label: 'Location'
      },
      {
        id: 4,
        label: 'Status'
      },
    ]
  },
  {
    id: 2,
    isActive: false,
    display: true,
    name: 'Airport',
    icon: 'airport',
    children: [
      {
        id: 1,
        label: 'Terminal'
      },
      {
        id: 2,
        label: 'Gates'
      },
      {
        id: 3,
        label: 'Location'
      },
      {
        id: 4,
        label: 'Status'
      },
    ]
  },
  {
    id: 3,
    isActive: false,
    display: true,
    name: 'Airline',
    icon: 'airline',
    children: [
      {
        id: 1,
        label: 'Terminal'
      },
      {
        id: 2,
        label: 'Gates'
      },
      {
        id: 3,
        label: 'Location'
      },
      {
        id: 4,
        label: 'Status'
      },
    ]
  },
  {
    id: 4,
    isActive: false,
    display: true,
    name: 'Flight',
    icon: 'flight',
    children: [
      {
        id: 1,
        label: 'Terminal'
      },
      {
        id: 2,
        label: 'Gates'
      },
      {
        id: 3,
        label: 'Location'
      },
      {
        id: 4,
        label: 'Status'
      },
    ]
  }
];

export const AirlinesTable =  [
  {
    id: 1,
    active : false,
    flight: "LY 337",
    airlines: "EA AI Israel Airlines",
    destination: "Amsterdam (AS)",
    arrivalTime: "05.10",
    timeStatus: [
      {
        id: 1,
        status: "delayed",
        active : false,
        airplaneDetails:{
          airline: "ELAL",
          flight: "LY337",
          airplaneType: "Boeing 737-800",
          gate: "A10",
          groundHandling: "Ground Team A",
          destination: "Brussels",
          weather: "22 °С",
          AIBT: "10:10:00",
          EOBT: "10:42:00",
          detail:[
            {
              id: 1,
              title: "Chock On",
              value: "0.3",
              max: "0.3",
              active: false
            },
            {
              id: 2,
              title: "Open doors",
              value: "0.2",
              max: "0.2",
              active: false
            },
            {
              id: 3,
              title: "Deplane passengers",
              value: "3.6",
              max: "3.7",
              active: false
            },
            {
              id: 4,
              title : "Service cabin",
              value: "10.1",
              max: "9.6",
              maxPlus: "5",
              active: false
            },
            {
              id: 5,
              title : "Service forward gallery",
              value: "4.1",
              max: "6.7",
              active: true
            },
            {
              id: 6,
              title : "Load forward compartment",
              value: "0",
              max: "7.1",
              active: false
            },
            {
              id: 7,
              title : "Board passengers",
              value: "0",
              max: "9.8",
              active: false
            },
            {
              id: 8,
              title : "Fuel airplane",
              value: "0",
              max: "7.9",
              active: false
            },
            {
              id: 9,
              title : "Chocks off/Pushback",
              value: "0",
              max: "1.0",
              active: false
            }
          ]
        },
      },
      {
        id: 2,
        status: "empty",
        active : false,
      },
      {
        id: 3,
        status: "empty",
        active : false,
      },
      {
        id: 4,
        status: "empty",
        active : false,
      },
      {
        id: 5,
        status: "empty",
        active : false,
      },
      {
        id: 6,
        status: "empty",
        active : false,
      },
      {
        id: 7,
        status: "empty",
        active : false,
      },
      {
        id: 8,
        status: "empty",
        active : false,
      },
      {
        id: 9,
        status: "empty",
        active : false,
      },
      {
        id: 10,
        status: "empty",
        active : false,
      },
      {
        id: 11,
        status: "empty",
        active : false,
      },
      {
        id: 12,
        status: "empty",
        active : false,
      },
      {
        id: 13,
        status: "empty",
        active : false,
      },
      {
        id: 14,
        status: "empty",
        active : false,
      },
      {
        id: 15,
        status: "empty",
        active : false,
      },
      {
        id: 16,
        status: "empty",
        active : false,
      },
      {
        id: 17,
        status: "empty",
        active : false,
      },
      {
        id: 18,
        status: "empty",
        active : false,
      },
      {
        id: 19,
        status: "empty",
        active : false,
      },
      {
        id: 20,
        status: "empty",
        active : false,
      },
      {
        id: 21,
        status: "empty",
        active : false,
      },
      {
        id: 22,
        status: "empty",
        active : false,
      },
      {
        id: 23,
        status: "empty",
        active : false,
      },
      {
        id: 24,
        status: "empty",
        active : false,
      },
    ]
  },
  {
    id: 2,
    active : false,
    flight: "LY 337",
    airlines: "EA AI Israel Airlines",
    destination: "Amsterdam (AS)",
    arrivalTime: "05.50",
    timeStatus: [
      {
        id: 1,
        status: "empty",
        active : false,
      },
      {
        id: 2,
        status: "ready",
        active : false,
        airplaneDetails:{
          airline: "ELAL",
          flight: "LY337",
          airplaneType: "Boeing 737-800",
          gate: "A10",
          groundHandling: "Ground Team A",
          destination: "Brussels",
          weather: "22 °С",
          AIBT: "10:10:00",
          EOBT: "10:42:00",
          detail:[
            {
              id: 1,
              title: "Chock On",
              value: "0.3",
              max: "0.3",
              active: false
            },
            {
              id: 2,
              title: "Open doors",
              value: "0.2",
              max: "0.2",
              active: false
            },
            {
              id: 3,
              title: "Deplane passengers",
              value: "3.6",
              max: "3.7",
              active: false
            },
            {
              id: 4,
              title : "Service cabin",
              value: "9.6",
              max: "9.6",
              // maxPlus: "0.5",
              active: false
            },
            {
              id: 5,
              title : "Service forward gallery",
              value: "4.1",
              max: "6.7",
              active: false
            },
            {
              id: 6,
              title : "Load forward compartment",
              value: "1",
              max: "7.1",
              active: true
            },
            {
              id: 7,
              title : "Board passengers",
              value: "0",
              max: "9.8",
              active: false
            },
            {
              id: 8,
              title : "Fuel airplane",
              value: "0",
              max: "7.9",
              active: false
            },
            {
              id: 9,
              title : "Chocks off/Pushback",
              value: "0",
              max: "1.0",
              active: false
            }
          ]
        },
      },
      {
        id: 3,
        status: "empty",
        active : false,
      },
      {
        id: 4,
        status: "empty",
        active : false,
      },
      {
        id: 5,
        status: "empty",
        active : false,
      },
      {
        id: 6,
        status: "empty",
        active : false,
      },
      {
        id: 7,
        status: "empty",
        active : false,
      },
      {
        id: 8,
        status: "empty",
        active : false,
      },
      {
        id: 9,
        status: "empty",
        active : false,
      },
      {
        id: 10,
        status: "empty",
        active : false,
      },
      {
        id: 11,
        status: "empty",
        active : false,
      },
      {
        id: 12,
        status: "empty",
        active : false,
      },
      {
        id: 13,
        status: "empty",
        active : false,
      },
      {
        id: 14,
        status: "empty",
        active : false,
      },
      {
        id: 15,
        status: "empty",
        active : false,
      },
      {
        id: 16,
        status: "empty",
        active : false,
      },
      {
        id: 17,
        status: "empty",
        active : false,
      },
      {
        id: 18,
        status: "empty",
        active : false,
      },
      {
        id: 19,
        status: "empty",
        active : false,
      },
      {
        id: 20,
        status: "empty",
        active : false,
      },
      {
        id: 21,
        status: "empty",
        active : false,
      },
      {
        id: 22,
        status: "empty",
        active : false,
      },
      {
        id: 23,
        status: "empty",
        active : false,
      },
      {
        id: 24,
        status: "empty",
        active : false,
      },
    ]
  },
  {
    id: 3,
    active : false,
    flight: "LY 337",
    airlines: "EA AI Israel Airlines",
    destination: "Amsterdam (AS)",
    arrivalTime: "06.30",
    timeStatus: [
      {
        id: 1,
        status: "empty",
        active : false,
      },
      {
        id: 2,
        status: "empty",
        active : false,
      },
      {
        id: 3,
        status: "ready",
        active : false,
        airplaneDetails:{
          airline: "ELAL",
          flight: "LY337",
          airplaneType: "Boeing 737-800",
          gate: "A10",
          groundHandling: "Ground Team A",
          destination: "Brussels",
          weather: "22 °С",
          AIBT: "10:10:00",
          EOBT: "10:42:00",
          detail:[
            {
              id: 1,
              title: "Chock On",
              value: "0.3",
              max: "0.3",
              active: false
            },
            {
              id: 2,
              title: "Open doors",
              value: "0.2",
              max: "0.2",
              active: false
            },
            {
              id: 3,
              title: "Deplane passengers",
              value: "3.6",
              max: "3.7",
              active: false
            },
            {
              id: 4,
              title : "Service cabin",
              value: "9.5",
              max: "9.6",
              // maxPlus: "0.5",
              active: false
            },
            {
              id: 5,
              title : "Service forward gallery",
              value: "6.1",
              max: "6.7",
              active: false
            },
            {
              id: 6,
              title : "Load forward compartment",
              value: "0",
              max: "7.1",
              active: false
            },
            {
              id: 7,
              title : "Board passengers",
              value: "2",
              max: "9.8",
              active: true
            },
            {
              id: 8,
              title : "Fuel airplane",
              value: "0",
              max: "7.9",
              active: false
            },
            {
              id: 9,
              title : "Chocks off/Pushback",
              value: "0",
              max: "1.0",
              active: false
            }
          ]
        }
      },
      {
        id: 4,
        status: "empty",
        active : false,
      },
      {
        id: 5,
        status: "empty",
        active : false,
      },
      {
        id: 6,
        status: "empty",
        active : false,
      },
      {
        id: 7,
        status: "empty",
        active : false,
      },
      {
        id: 8,
        status: "empty",
        active : false,
      },
      {
        id: 9,
        status: "empty",
        active : false,
      },
      {
        id: 10,
        status: "empty",
        active : false,
      },
      {
        id: 11,
        status: "empty",
        active : false,
      },
      {
        id: 12,
        status: "empty",
        active : false,
      },
      {
        id: 13,
        status: "empty",
        active : false,
      },
      {
        id: 14,
        status: "empty",
        active : false,
      },
      {
        id: 15,
        status: "empty",
        active : false,
      },
      {
        id: 16,
        status: "empty",
        active : false,
      },
      {
        id: 17,
        status: "empty",
        active : false,
      },
      {
        id: 18,
        status: "empty",
        active : false,
      },
      {
        id: 19,
        status: "empty",
        active : false,
      },
      {
        id: 20,
        status: "empty",
        active : false,
      },
      {
        id: 21,
        status: "empty",
        active : false,
      },
      {
        id: 22,
        status: "empty",
        active : false,
      },
      {
        id: 23,
        status: "empty",
        active : false,
      },
      {
        id: 24,
        status: "empty",
        active : false,
      },
    ]
  },
  {
    id: 4,
    active : false,
    flight: "LY 337",
    airlines: "EA AI Israel Airlines",
    destination: "Amsterdam (AS)",
    arrivalTime: "06.15",
    timeStatus: [
      {
        id: 1,
        status: "empty",
        active : false,
      },
      {
        id: 2,
        status: "ready",
        active : false,
        airplaneDetails:{
          airline: "ELAL",
          flight: "LY337",
          airplaneType: "Boeing 737-800",
          gate: "A10",
          groundHandling: "Ground Team A",
          destination: "Brussels",
          weather: "22 °С",
          AIBT: "10:10:00",
          EOBT: "10:42:00",
          detail:[
            {
              id: 1,
              title: "Chock On",
              value: "0.3",
              max: "0.3",
              active: false
            },
            {
              id: 2,
              title: "Open doors",
              value: "0.2",
              max: "0.2",
              active: false
            },
            {
              id: 3,
              title: "Deplane passengers",
              value: "3.6",
              max: "3.7",
              active: false
            },
            {
              id: 4,
              title : "Service cabin",
              value: "9.6",
              max: "9.6",
              // maxPlus: "0.5",
              active: false
            },
            {
              id: 5,
              title : "Service forward gallery",
              value: "4.1",
              max: "6.7",
              active: false
            },
            {
              id: 6,
              title : "Load forward compartment",
              value: "7.1",
              max: "7.1",
              active: false
            },
            {
              id: 7,
              title : "Board passengers",
              value: "5",
              max: "9.8",
              active: true
            },
            {
              id: 8,
              title : "Fuel airplane",
              value: "0",
              max: "7.9",
              active: false
            },
            {
              id: 9,
              title : "Chocks off/Pushback",
              value: "0",
              max: "1.0",
              active: false
            }
          ]
        },
      },
      {
        id: 3,
        status: "empty",
        active : false,
      },
      {
        id: 4,
        status: "empty",
        active : false,
      },
      {
        id: 5,
        status: "empty",
        active : false,
      },
      {
        id: 6,
        status: "empty",
        active : false,
      },
      {
        id: 7,
        status: "ready",
        active : false,
        airplaneDetails:{
          airline: "ELAL",
          flight: "LY337",
          airplaneType: "Boeing 737-800",
          gate: "A10",
          groundHandling: "Ground Team A",
          destination: "Brussels",
          weather: "22 °С",
          AIBT: "10:10:00",
          EOBT: "10:42:00",
          detail:[
            {
              id: 1,
              title: "Chock On",
              value: "0.3",
              max: "0.3",
              active: false
            },
            {
              id: 2,
              title: "Open doors",
              value: "0.2",
              max: "0.2",
              active: false
            },
            {
              id: 3,
              title: "Deplane passengers",
              value: "3.6",
              max: "3.7",
              active: false
            },
            {
              id: 4,
              title : "Service cabin",
              value: "9.6",
              max: "9.6",
              // maxPlus: "0.5",
              active: false
            },
            {
              id: 5,
              title : "Service forward gallery",
              value: "4.1",
              max: "6.7",
              active: true
            },
            {
              id: 6,
              title : "Load forward compartment",
              value: "0",
              max: "7.1",
              active: false
            },
            {
              id: 7,
              title : "Board passengers",
              value: "0",
              max: "9.8",
              active: false
            },
            {
              id: 8,
              title : "Fuel airplane",
              value: "0",
              max: "7.9",
              active: false
            },
            {
              id: 9,
              title : "Chocks off/Pushback",
              value: "0",
              max: "1.0",
              active: false
            }
          ]
        },
      },
      {
        id: 8,
        status: "empty",
        active : false,
      },
      {
        id: 9,
        status: "empty",
        active : false,
      },
      {
        id: 10,
        status: "empty",
        active : false,
      },
      {
        id: 11,
        status: "empty",
        active : false,
      },
      {
        id: 12,
        status: "empty",
        active : false,
      },
      {
        id: 13,
        status: "empty",
        active : false,
      },
      {
        id: 14,
        status: "empty",
        active : false,
      },
      {
        id: 15,
        status: "empty",
        active : false,
      },
      {
        id: 16,
        status: "empty",
        active : false,
      },
      {
        id: 17,
        status: "empty",
        active : false,
      },
      {
        id: 18,
        status: "empty",
        active : false,
      },
      {
        id: 19,
        status: "empty",
        active : false,
      },
      {
        id: 20,
        status: "empty",
        active : false,
      },
      {
        id: 21,
        status: "empty",
        active : false,
      },
      {
        id: 22,
        status: "empty",
        active : false,
      },
      {
        id: 23,
        status: "empty",
        active : false,
      },
      {
        id: 24,
        status: "empty",
        active : false,
      },
    ]
  },
  {
    id: 5,
    active : false,
    flight: "LY 337",
    airlines: "EA AI Israel Airlines",
    destination: "Amsterdam (AS)",
    arrivalTime: "07.00",
    timeStatus: [
      {
        id: 1,
        status: "empty",
        active : false,
      },
      {
        id: 2,
        status: "empty",
        active : false,
      },
      {
        id: 3,
        status: "delayed",
        active : false,
        airplaneDetails:{
          airline: "ELAL",
          flight: "LY337",
          airplaneType: "Boeing 737-800",
          gate: "A10",
          groundHandling: "Ground Team A",
          destination: "Brussels",
          weather: "22 °С",
          AIBT: "10:10:00",
          EOBT: "10:42:00",
          detail:[
            {
              id: 1,
              title: "Chock On",
              value: "0.3",
              max: "0.3",
              active: false
            },
            {
              id: 2,
              title: "Open doors",
              value: "0.2",
              max: "0.2",
              active: false
            },
            {
              id: 3,
              title: "Deplane passengers",
              value: "3.6",
              max: "3.7",
              active: false
            },
            {
              id: 4,
              title : "Service cabin",
              value: "10.1",
              max: "9.6",
              maxPlus: "5",
              active: false
            },
            {
              id: 5,
              title : "Service forward gallery",
              value: "4.1",
              max: "6.7",
              active: true
            },
            {
              id: 6,
              title : "Load forward compartment",
              value: "0",
              max: "7.1",
              active: false
            },
            {
              id: 7,
              title : "Board passengers",
              value: "0",
              max: "9.8",
              active: false
            },
            {
              id: 8,
              title : "Fuel airplane",
              value: "0",
              max: "7.9",
              active: false
            },
            {
              id: 9,
              title : "Chocks off/Pushback",
              value: "0",
              max: "1.0",
              active: false
            }
          ]
        },
      },
      {
        id: 4,
        status: "empty",
        active : false,
      },
      {
        id: 5,
        status: "empty",
        active : false,
      },
      {
        id: 6,
        status: "empty",
        active : false,
      },
      {
        id: 7,
        status: "empty",
        active : false,
      },
      {
        id: 8,
        status: "empty",
        active : false,
      },
      {
        id: 9,
        status: "empty",
        active : false,
      },
      {
        id: 10,
        status: "empty",
        active : false,
      },
      {
        id: 11,
        status: "empty",
        active : false,
      },
      {
        id: 12,
        status: "empty",
        active : false,
      },
      {
        id: 13,
        status: "empty",
        active : false,
      },
      {
        id: 14,
        status: "empty",
        active : false,
      },
      {
        id: 15,
        status: "empty",
        active : false,
      },
      {
        id: 16,
        status: "empty",
        active : false,
      },
      {
        id: 17,
        status: "empty",
        active : false,
      },
      {
        id: 18,
        status: "empty",
        active : false,
      },
      {
        id: 19,
        status: "empty",
        active : false,
      },
      {
        id: 20,
        status: "empty",
        active : false,
      },
      {
        id: 21,
        status: "empty",
        active : false,
      },
      {
        id: 22,
        status: "empty",
        active : false,
      },
      {
        id: 23,
        status: "empty",
        active : false,
      },
      {
        id: 24,
        status: "empty",
        active : false,
      },
    ]
  },
  {
    id: 6,
    active : false,
    flight: "LY 337",
    airlines: "EA AI Israel Airlines",
    destination: "Amsterdam (AS)",
    arrivalTime: "08.10",
    timeStatus: [
      {
        id: 1,
        status: "empty",
        active : false,
      },
      {
        id: 2,
        status: "empty",
        active : false,
      },
      {
        id: 3,
        status: "empty",
        active : false,
      },
      {
        id: 4,
        status: "ready",
        active : false,
        airplaneDetails:{
          airline: "ELAL",
          flight: "LY337",
          airplaneType: "Boeing 737-800",
          gate: "A10",
          groundHandling: "Ground Team A",
          destination: "Brussels",
          weather: "22 °С",
          AIBT: "10:10:00",
          EOBT: "10:42:00",
          detail:[
            {
              id: 1,
              title: "Chock On",
              value: "0.3",
              max: "0.3",
              active: false
            },
            {
              id: 2,
              title: "Open doors",
              value: "0.2",
              max: "0.2",
              active: false
            },
            {
              id: 3,
              title: "Deplane passengers",
              value: "3.6",
              max: "3.7",
              active: false
            },
            {
              id: 4,
              title : "Service cabin",
              value: "10.1",
              max: "9.6",
              // maxPlus: "0.5",
              active: false
            },
            {
              id: 5,
              title : "Service forward gallery",
              value: "4.1",
              max: "6.7",
              active: true
            },
            {
              id: 6,
              title : "Load forward compartment",
              value: "0",
              max: "7.1",
              active: false
            },
            {
              id: 7,
              title : "Board passengers",
              value: "0",
              max: "9.8",
              active: false
            },
            {
              id: 8,
              title : "Fuel airplane",
              value: "0",
              max: "7.9",
              active: false
            },
            {
              id: 9,
              title : "Chocks off/Pushback",
              value: "0",
              max: "1.0",
              active: false
            }
          ]
        },
      },
      {
        id: 5,
        status: "empty",
        active : false,
      },
      {
        id: 6,
        status: "empty",
        active : false,
      },
      {
        id: 7,
        status: "empty",
        active : false,
      },
      {
        id: 8,
        status: "empty",
        active : false,
      },
      {
        id: 9,
        status: "empty",
        active : false,
      },
      {
        id: 10,
        status: "empty",
        active : false,
      },
      {
        id: 11,
        status: "empty",
        active : false,
      },
      {
        id: 12,
        status: "empty",
        active : false,
      },
      {
        id: 13,
        status: "empty",
        active : false,
      },
      {
        id: 14,
        status: "empty",
        active : false,
      },
      {
        id: 15,
        status: "empty",
        active : false,
      },
      {
        id: 16,
        status: "empty",
        active : false,
      },
      {
        id: 17,
        status: "empty",
        active : false,
      },
      {
        id: 18,
        status: "empty",
        active : false,
      },
      {
        id: 19,
        status: "empty",
        active : false,
      },
      {
        id: 20,
        status: "empty",
        active : false,
      },
      {
        id: 21,
        status: "empty",
        active : false,
      },
      {
        id: 22,
        status: "empty",
        active : false,
      },
      {
        id: 23,
        status: "empty",
        active : false,
      },
      {
        id: 24,
        status: "empty",
        active : false,
      },
    ]
  },
  {
    id: 7,
    active : false,
    flight: "LY 337",
    airlines: "EA AI Israel Airlines",
    destination: "Amsterdam (AS)",
    arrivalTime: "08.45",
    timeStatus: [
      {
        id: 1,
        status: "empty",
        active : false,
      },
      {
        id: 2,
        status: "empty",
        active : false,
      },
      {
        id: 3,
        status: "empty",
        active : false,
      },
      {
        id: 4,
        status: "empty",
        active : false,
      },
      {
        id: 5,
        status: "empty",
        active : false,
      },
      {
        id: 6,
        status: "empty",
        active : false,
      },
      {
        id: 7,
        status: "empty",
        active : false,
      },
      {
        id: 8,
        status: "empty",
        active : false,
      },
      {
        id: 9,
        status: "empty",
        active : false,
      },
      {
        id: 10,
        status: "empty",
        active : false,
      },
      {
        id: 11,
        status: "empty",
        active : false,
      },
      {
        id: 12,
        status: "empty",
        active : false,
      },
      {
        id: 13,
        status: "empty",
        active : false,
      },
      {
        id: 14,
        status: "empty",
        active : false,
      },
      {
        id: 15,
        status: "empty",
        active : false,
      },
      {
        id: 16,
        status: "empty",
        active : false,
      },
      {
        id: 17,
        status: "empty",
        active : false,
      },
      {
        id: 18,
        status: "empty",
        active : false,
      },
      {
        id: 19,
        status: "empty",
        active : false,
      },
      {
        id: 20,
        status: "empty",
        active : false,
      },
      {
        id: 21,
        status: "empty",
        active : false,
      },
      {
        id: 22,
        status: "empty",
        active : false,
      },
      {
        id: 23,
        status: "empty",
        active : false,
      },
      {
        id: 24,
        status: "empty",
        active : false,
      },
    ]
  },
  {
    id: 8,
    active : false,
    flight: "LY 337",
    airlines: "EA AI Israel Airlines",
    destination: "Amsterdam (AS)",
    arrivalTime: "09.25",
    timeStatus: [
      {
        id: 1,
        status: "empty",
        active : false,
      },
      {
        id: 2,
        status: "empty",
        active : false,
      },
      {
        id: 3,
        status: "empty",
        active : false,
      },
      {
        id: 4,
        status: "empty",
        active : false,
      },
      {
        id: 5,
        status: "empty",
        active : false,
      },
      {
        id: 6,
        status: "empty",
        active : false,
      },
      {
        id: 7,
        status: "waiting",
        active : false,
        airplaneDetails:{
          airline: "ELAL",
          flight: "LY337",
          airplaneType: "Boeing 737-800",
          gate: "A10",
          groundHandling: "Ground Team A",
          destination: "Brussels",
          weather: "22 °С",
          AIBT: "10:10:00",
          EOBT: "10:42:00",
          detail:[
            {
              id: 1,
              title: "Chock On",
              value: "0.3",
              max: "0.3",
              active: false
            },
            {
              id: 2,
              title: "Open doors",
              value: "0.2",
              max: "0.2",
              active: false
            },
            {
              id: 3,
              title: "Deplane passengers",
              value: "3.6",
              max: "3.7",
              active: false
            },
            {
              id: 4,
              title : "Service cabin",
              value: "10.1",
              max: "9.6",
              // maxPlus: "0.5",
              active: false
            },
            {
              id: 5,
              title : "Service forward gallery",
              value: "4.1",
              max: "6.7",
              active: true
            },
            {
              id: 6,
              title : "Load forward compartment",
              value: "0",
              max: "7.1",
              active: false
            },
            {
              id: 7,
              title : "Board passengers",
              value: "0",
              max: "9.8",
              active: false
            },
            {
              id: 8,
              title : "Fuel airplane",
              value: "0",
              max: "7.9",
              active: false
            },
            {
              id: 9,
              title : "Chocks off/Pushback",
              value: "0",
              max: "1.0",
              active: false
            }
          ]
        },
      },
      {
        id: 8,
        status: "empty",
        active : false,
      },
      {
        id: 9,
        status: "empty",
        active : false,
      },
      {
        id: 10,
        status: "empty",
        active : false,
      },
      {
        id: 11,
        status: "empty",
        active : false,
      },
      {
        id: 12,
        status: "empty",
        active : false,
      },
      {
        id: 13,
        status: "empty",
        active : false,
      },
      {
        id: 14,
        status: "empty",
        active : false,
      },
      {
        id: 15,
        status: "empty",
        active : false,
      },
      {
        id: 16,
        status: "empty",
        active : false,
      },
      {
        id: 17,
        status: "empty",
        active : false,
      },
      {
        id: 18,
        status: "empty",
        active : false,
      },
      {
        id: 19,
        status: "empty",
        active : false,
      },
      {
        id: 20,
        status: "empty",
        active : false,
      },
      {
        id: 21,
        status: "empty",
        active : false,
      },
      {
        id: 22,
        status: "empty",
        active : false,
      },
      {
        id: 23,
        status: "empty",
        active : false,
      },
      {
        id: 24,
        status: "empty",
        active : false,
      },
    ]
  },
  {
    id: 9,
    active : false,
    flight: "LY 337",
    airlines: "EA AI Israel Airlines",
    destination: "Amsterdam (AS)",
    arrivalTime: "10.30",
    timeStatus: [
      {
        id: 1,
        status: "empty",
        active : false,
      },
      {
        id: 2,
        status: "empty",
        active : false,
      },
      {
        id: 3,
        status: "empty",
        active : false,
      },
      {
        id: 4,
        status: "empty",
        active : false,
      },
      {
        id: 5,
        status: "empty",
        active : false,
      },
      {
        id: 6,
        status: "empty",
        active : false,
      },
      {
        id: 7,
        status: "empty",
        active : false,
      },
      {
        id: 8,
        status: "empty",
        active : false,
      },
      {
        id: 9,
        status: "waiting",
        active : false,
        airplaneDetails:{
          airline: "ELAL",
          flight: "LY337",
          airplaneType: "Boeing 737-800",
          gate: "A10",
          groundHandling: "Ground Team A",
          destination: "Brussels",
          weather: "22 °С",
          AIBT: "10:10:00",
          EOBT: "10:42:00",
          detail:[
            {
              id: 1,
              title: "Chock On",
              value: "0.3",
              max: "0.3",
              active: false
            },
            {
              id: 2,
              title: "Open doors",
              value: "0.2",
              max: "0.2",
              active: false
            },
            {
              id: 3,
              title: "Deplane passengers",
              value: "3.6",
              max: "3.7",
              active: false
            },
            {
              id: 4,
              title : "Service cabin",
              value: "10.1",
              max: "9.6",
              // maxPlus: "0.5",
              active: false
            },
            {
              id: 5,
              title : "Service forward gallery",
              value: "4.1",
              max: "6.7",
              active: true
            },
            {
              id: 6,
              title : "Load forward compartment",
              value: "0",
              max: "7.1",
              active: false
            },
            {
              id: 7,
              title : "Board passengers",
              value: "0",
              max: "9.8",
              active: false
            },
            {
              id: 8,
              title : "Fuel airplane",
              value: "0",
              max: "7.9",
              active: false
            },
            {
              id: 9,
              title : "Chocks off/Pushback",
              value: "0",
              max: "1.0",
              active: false
            }
          ]
        },
      },
      {
        id: 10,
        status: "empty",
        active : false,
      },
      {
        id: 11,
        status: "empty",
        active : false,
      },
      {
        id: 12,
        status: "empty",
        active : false,
      },
      {
        id: 13,
        status: "empty",
        active : false,
      },
      {
        id: 14,
        status: "empty",
        active : false,
      },
      {
        id: 15,
        status: "empty",
        active : false,
      },
      {
        id: 16,
        status: "empty",
        active : false,
      },
      {
        id: 17,
        status: "empty",
        active : false,
      },
      {
        id: 18,
        status: "empty",
        active : false,
      },
      {
        id: 19,
        status: "empty",
        active : false,
      },
      {
        id: 20,
        status: "empty",
        active : false,
      },
      {
        id: 21,
        status: "empty",
        active : false,
      },
      {
        id: 22,
        status: "empty",
        active : false,
      },
      {
        id: 23,
        status: "empty",
        active : false,
      },
      {
        id: 24,
        status: "empty",
        active : false,
      },
    ]
  },
  {
    id: 10,
    active : false,
    flight: "LY 337",
    airlines: "EA AI Israel Airlines",
    destination: "Amsterdam (AS)",
    arrivalTime: "11.10",
    timeStatus: [
      {
        id: 1,
        status: "empty",
        active : false,
      },
      {
        id: 2,
        status: "empty",
        active : false,
      },
      {
        id: 3,
        status: "empty",
        active : false,
      },
      {
        id: 4,
        status: "empty",
        active : false,
      },
      {
        id: 5,
        status: "empty",
        active : false,
      },
      {
        id: 6,
        status: "empty",
        active : false,
      },
      {
        id: 7,
        status: "empty",
        active : false,
      },
      {
        id: 8,
        status: "empty",
        active : false,
      },
      {
        id: 9,
        status: "empty",
        active : false,
      },
      {
        id: 10,
        status: "empty",
        active : false,
      },
      {
        id: 11,
        status: "empty",
        active : false,
      },
      {
        id: 12,
        status: "empty",
        active : false,
      },
      {
        id: 13,
        status: "empty",
        active : false,
      },
      {
        id: 14,
        status: "empty",
        active : false,
      },
      {
        id: 15,
        status: "empty",
        active : false,
      },
      {
        id: 16,
        status: "empty",
        active : false,
      },
      {
        id: 17,
        status: "empty",
        active : false,
      },
      {
        id: 18,
        status: "empty",
        active : false,
      },
      {
        id: 19,
        status: "empty",
        active : false,
      },
      {
        id: 20,
        status: "empty",
        active : false,
      },
      {
        id: 21,
        status: "empty",
        active : false,
      },
      {
        id: 22,
        status: "empty",
        active : false,
      },
      {
        id: 23,
        status: "empty",
        active : false,
      },
      {
        id: 24,
        status: "empty",
        active : false,
      },
    ]
  },
  {
    id: 11,
    active : false,
    flight: "LY 337",
    airlines: "EA AI Israel Airlines",
    destination: "Amsterdam (AS)",
    arrivalTime: "05.10",
    timeStatus: [
      {
        id: 1,
        status: "empty",
        active : false,
      },
      {
        id: 2,
        status: "empty",
        active : false,
      },
      {
        id: 3,
        status: "empty",
        active : false,
      },
      {
        id: 4,
        status: "empty",
        active : false,
      },
      {
        id: 5,
        status: "empty",
        active : false,
      },
      {
        id: 6,
        status: "empty",
        active : false,
      },
      {
        id: 7,
        status: "empty",
        active : false,
      },
      {
        id: 8,
        status: "empty",
        active : false,
      },
      {
        id: 9,
        status: "empty",
        active : false,
      },
      {
        id: 10,
        status: "empty",
        active : false,
      },
      {
        id: 11,
        status: "empty",
        active : false,
      },
      {
        id: 12,
        status: "empty",
        active : false,
      },
      {
        id: 13,
        status: "empty",
        active : false,
      },
      {
        id: 14,
        status: "empty",
        active : false,
      },
      {
        id: 15,
        status: "empty",
        active : false,
      },
      {
        id: 16,
        status: "empty",
        active : false,
      },
      {
        id: 17,
        status: "empty",
        active : false,
      },
      {
        id: 18,
        status: "empty",
        active : false,
      },
      {
        id: 19,
        status: "empty",
        active : false,
      },
      {
        id: 20,
        status: "empty",
        active : false,
      },
      {
        id: 21,
        status: "empty",
        active : false,
      },
      {
        id: 22,
        status: "empty",
        active : false,
      },
      {
        id: 23,
        status: "empty",
        active : false,
      },
      {
        id: 24,
        status: "empty",
        active : false,
      },
    ]
  },
  {
    id: 12,
    active : false,
    flight: "LY 337",
    airlines: "EA AI Israel Airlines",
    destination: "Amsterdam (AS)",
    arrivalTime: "05.10",
    timeStatus: [
      {
        id: 1,
        status: "empty",
        active : false,
      },
      {
        id: 2,
        status: "empty",
        active : false,
      },
      {
        id: 3,
        status: "empty",
        active : false,
      },
      {
        id: 4,
        status: "empty",
        active : false,
      },
      {
        id: 5,
        status: "empty",
        active : false,
      },
      {
        id: 6,
        status: "empty",
        active : false,
      },
      {
        id: 7,
        status: "empty",
        active : false,
      },
      {
        id: 8,
        status: "empty",
        active : false,
      },
      {
        id: 9,
        status: "empty",
        active : false,
      },
      {
        id: 10,
        status: "empty",
        active : false,
      },
      {
        id: 11,
        status: "empty",
        active : false,
      },
      {
        id: 12,
        status: "empty",
        active : false,
      },
      {
        id: 13,
        status: "empty",
        active : false,
      },
      {
        id: 14,
        status: "empty",
        active : false,
      },
      {
        id: 15,
        status: "empty",
        active : false,
      },
      {
        id: 16,
        status: "empty",
        active : false,
      },
      {
        id: 17,
        status: "empty",
        active : false,
      },
      {
        id: 18,
        status: "empty",
        active : false,
      },
      {
        id: 19,
        status: "empty",
        active : false,
      },
      {
        id: 20,
        status: "empty",
        active : false,
      },
      {
        id: 21,
        status: "empty",
        active : false,
      },
      {
        id: 22,
        status: "empty",
        active : false,
      },
      {
        id: 23,
        status: "empty",
        active : false,
      },
      {
        id: 24,
        status: "empty",
        active : false,
      },
    ]
  },
  {
    id: 13,
    active : false,
    flight: "LY 337",
    airlines: "EA AI Israel Airlines",
    destination: "Amsterdam (AS)",
    arrivalTime: "05.10",
    timeStatus: [
      {
        id: 1,
        status: "empty",
        active : false,
      },
      {
        id: 2,
        status: "empty",
        active : false,
      },
      {
        id: 3,
        status: "empty",
        active : false,
      },
      {
        id: 4,
        status: "empty",
        active : false,
      },
      {
        id: 5,
        status: "empty",
        active : false,
      },
      {
        id: 6,
        status: "empty",
        active : false,
      },
      {
        id: 7,
        status: "empty",
        active : false,
      },
      {
        id: 8,
        status: "empty",
        active : false,
      },
      {
        id: 9,
        status: "empty",
        active : false,
      },
      {
        id: 10,
        status: "empty",
        active : false,
      },
      {
        id: 11,
        status: "empty",
        active : false,
      },
      {
        id: 12,
        status: "empty",
        active : false,
      },
      {
        id: 13,
        status: "empty",
        active : false,
      },
      {
        id: 14,
        status: "empty",
        active : false,
      },
      {
        id: 15,
        status: "empty",
        active : false,
      },
      {
        id: 16,
        status: "empty",
        active : false,
      },
      {
        id: 17,
        status: "empty",
        active : false,
      },
      {
        id: 18,
        status: "empty",
        active : false,
      },
      {
        id: 19,
        status: "empty",
        active : false,
      },
      {
        id: 20,
        status: "empty",
        active : false,
      },
      {
        id: 21,
        status: "empty",
        active : false,
      },
      {
        id: 22,
        status: "empty",
        active : false,
      },
      {
        id: 23,
        status: "empty",
        active : false,
      },
      {
        id: 24,
        status: "empty",
        active : false,
      },
    ]
  },
  {
    id: 14,
    active : false,
    flight: "LY 337",
    airlines: "EA AI Israel Airlines",
    destination: "Amsterdam (AS)",
    arrivalTime: "05.10",
    timeStatus: [
      {
        id: 1,
        status: "empty",
        active : false,
      },
      {
        id: 2,
        status: "empty",
        active : false,
      },
      {
        id: 3,
        status: "empty",
        active : false,
      },
      {
        id: 4,
        status: "empty",
        active : false,
      },
      {
        id: 5,
        status: "empty",
        active : false,
      },
      {
        id: 6,
        status: "empty",
        active : false,
      },
      {
        id: 7,
        status: "empty",
        active : false,
      },
      {
        id: 8,
        status: "empty",
        active : false,
      },
      {
        id: 9,
        status: "empty",
        active : false,
      },
      {
        id: 10,
        status: "empty",
        active : false,
      },
      {
        id: 11,
        status: "empty",
        active : false,
      },
      {
        id: 12,
        status: "empty",
        active : false,
      },
      {
        id: 13,
        status: "empty",
        active : false,
      },
      {
        id: 14,
        status: "empty",
        active : false,
      },
      {
        id: 15,
        status: "empty",
        active : false,
      },
      {
        id: 16,
        status: "empty",
        active : false,
      },
      {
        id: 17,
        status: "empty",
        active : false,
      },
      {
        id: 18,
        status: "empty",
        active : false,
      },
      {
        id: 19,
        status: "empty",
        active : false,
      },
      {
        id: 20,
        status: "empty",
        active : false,
      },
      {
        id: 21,
        status: "empty",
        active : false,
      },
      {
        id: 22,
        status: "empty",
        active : false,
      },
      {
        id: 23,
        status: "empty",
        active : false,
      },
      {
        id: 24,
        status: "empty",
        active : false,
      },
    ]
  },
  {
    id: 15,
    active : false,
    flight: "LY 337",
    airlines: "EA AI Israel Airlines",
    destination: "Amsterdam (AS)",
    arrivalTime: "05.10",
    timeStatus: [
      {
        id: 1,
        status: "empty",
        active : false,
      },
      {
        id: 2,
        status: "empty",
        active : false,
      },
      {
        id: 3,
        status: "empty",
        active : false,
      },
      {
        id: 4,
        status: "empty",
        active : false,
      },
      {
        id: 5,
        status: "empty",
        active : false,
      },
      {
        id: 6,
        status: "empty",
        active : false,
      },
      {
        id: 7,
        status: "empty",
        active : false,
      },
      {
        id: 8,
        status: "empty",
        active : false,
      },
      {
        id: 9,
        status: "empty",
        active : false,
      },
      {
        id: 10,
        status: "empty",
        active : false,
      },
      {
        id: 11,
        status: "empty",
        active : false,
      },
      {
        id: 12,
        status: "empty",
        active : false,
      },
      {
        id: 13,
        status: "empty",
        active : false,
      },
      {
        id: 14,
        status: "empty",
        active : false,
      },
      {
        id: 15,
        status: "empty",
        active : false,
      },
      {
        id: 16,
        status: "empty",
        active : false,
      },
      {
        id: 17,
        status: "empty",
        active : false,
      },
      {
        id: 18,
        status: "empty",
        active : false,
      },
      {
        id: 19,
        status: "empty",
        active : false,
      },
      {
        id: 20,
        status: "empty",
        active : false,
      },
      {
        id: 21,
        status: "empty",
        active : false,
      },
      {
        id: 22,
        status: "empty",
        active : false,
      },
      {
        id: 23,
        status: "empty",
        active : false,
      },
      {
        id: 24,
        status: "empty",
        active : false,
      },
    ]
  },
]
