import React from 'react'
import "./Cameras.scss"
import videoImg from "./video-item.png"

export const Cameras = (
  {title,
    videoUrl,
    active,
    durationVideo
  }) => {

  const focus = active ? "active" : ""

  return (
    <div className="cameras-box-item ">
      <span className="cameras-box-item-title">{title}</span>
      <div className={`video-item-box  ${focus}`}>
        <img src={videoImg} alt="" className="video-item"/>
        <span className="video-item-duration">{durationVideo}</span>
      </div>

    </div>
  )
}
