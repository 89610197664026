import React from 'react'
import { Search } from '../../../assets/img-svg/all-icons'
import "./SearchHeader.scss"

export const SearchHeader = ({activeSearch, onActiveSearch})=> {

  const active = activeSearch ? "active" : ""

  return (
    <div className="search-box pointer " onClick={ (e) => onActiveSearch(e)}>
      <Search width="17px" className="" />
      <div className={`search-box-input search-box-style ${active}`}>
        <input type="text" className="search-input "/>
        <Search width="17px" className="search-box-input-icon" />

        {/*  opacity = 0  */}
        <div className="search-box-input-answer search-box-style">
            <div className="input-answer-box">

            </div>
        </div>


      </div>
    </div>
  )
}
