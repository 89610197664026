import React, { useEffect, useState } from 'react'
import {
  Apply,
  ArrowLeft,
  ExclamationMark,
  CommentInactive,
  CommentActive,
} from '../../../../../assets/img-svg/all-icons'
import { DetailItemComments } from './DetailItemComments/DetailItemComments'
import './DetailTableItem.scss'


export const DetailTableItem = (
  {category,
    critical,
    duration,
    groundTeam,
    attention,
    startTimeInMinutes,
    progressInMinutes,
    endTimeInMinutes,
    comment,
    details,
  }) => {

  const [commitActive, setCommitActive] = useState(false)
  const [commitAdd, setCommitAdd] = useState(false)
  const [detailItem, setDetailItem] = useState(false)

  const startMrStyle = startTimeInMinutes * 100 / 60
  const endWidthStyle = (endTimeInMinutes - startTimeInMinutes) * 100 / 60
  const progressMaxWidth = (progressInMinutes - startTimeInMinutes) * 100 / 60

  let styleProgress = (Number(endTimeInMinutes) < Number(progressInMinutes)) ? "red" : "green"

  // open detail category
  const onClickDetail = () => {
    setDetailItem ( prev => !prev)
  }

  // opens the add comment menu
  const onClickCommit = () => {
    setCommitActive ( prev => !prev)
  }

  // close comment menu
  const onCommitClose = () => {
    setCommitActive (false)
    setCommitAdd ( false)
  }

  // opens the add comment menu
  const onClickCommitAdd = () => {
    setCommitActive ( prev => !prev)
    setCommitAdd ( prev => !prev)
  }

  // need to ensure that the timelines are correctly positioned with progress
  useEffect( () => {
     let timeLineWidth = document.querySelector(".timeline-box")
     let timeWidth = document.querySelector(".airplane-detail-table-header-timeline.line.bottom")
     timeWidth.style.maxWidth = `${timeLineWidth.scrollWidth}px`
  })

  let commit;
  if (comment.length !== 0 ) {
    commit = <CommentActive width="22px" className="comment-icon active pointer" onClick={onClickCommit}/>
  } else {
    commit = <CommentInactive width="27px" className="comment-icon pointer" onClick={onClickCommitAdd}/>
  }


  return (
    <>
    <div className="airplane-detail-table-content-row">
      <div className="airplane-detail-table-header-title">
        <div className="airplane-detail-table-header-item">
          {
            details.length !== 0 &&
          <div className="airplane-detail-table-header-item details-box">
            <div className="box pointer" onClick={onClickDetail}>
              { !detailItem
                ? <ArrowLeft width="10px" height="6px" style={{transform: "rotateZ(0deg)"}} className="airplane-detail-content-icon_color" />
                : <ArrowLeft width="10px" height="6px" style={{transform: "rotateZ(180deg)"}} className="airplane-detail-content-icon_color" />
              }
            </div>
          </div>
          }

          <span className="airplane-detail-table-header-item-title">{category}</span>
          {  attention &&
                          <div className="attention-box">
                            <ExclamationMark width="3px" height="10px" style={{fill: "#fff"}} />
                            <div className="attention-box-hint">
                              <span>Some short text </span>
                            </div>
                          </div>
          }
        </div>
        <span className="airplane-detail-table-header-item">{critical && <Apply width="14px" className="airplane-detail-content-icon_color" />}</span>
        <span className="airplane-detail-table-header-item">{duration}</span>
        <span className="airplane-detail-table-header-item">{groundTeam}</span>
      </div>

      <div className="airplane-detail-table-header-timeline">
        <div className="timeline-box">
          <div className="timeline-planned" style={{marginLeft:`${startMrStyle}%`, width:`${endWidthStyle}%` }} >
            <div className={`timeline-planned-progress progress_line ${styleProgress}`} style={{marginLeft:`${startMrStyle}%`, maxWidth:`${progressMaxWidth}%`}} >
            </div>
          </div>
        </div>
        <div className={`comment-icon-box ${(comment.length !== 0) && "active"}`} >
          {commit}
        </div>

        {
          commitActive && <DetailItemComments
                              comment={comment}
                              commitAdd={commitAdd}
                              onCommitClose={onCommitClose}
                            />
        }

      </div>
    </div>
        {
          detailItem &&
          <div className="detail-item">
            {details.map(item => <DetailItem key={item.id} {...item} />)}
          </div>
        }
    </>
  )
}

const DetailItem = (
  { title,
  duration,
  groundTeam,
  startTimeInMinutes,
  status
  }) => {

  const startMrStyle = startTimeInMinutes * 100 / 60

  return (
    <div className="airplane-detail-table-content-row">
        <div className="airplane-detail-table-header-title">
            <div className="airplane-detail-table-header-item">
              <span className="airplane-detail-table-header-item-title">{title}</span>
            </div>
          <span className="airplane-detail-table-header-item"></span>
            <span className="airplane-detail-table-header-item">{duration}</span>
            <span className="airplane-detail-table-header-item">{groundTeam}</span>
        </div>

        <div className="airplane-detail-table-header-timeline">
          <div className={`time-circle ${status}`} style={{marginLeft:`${startMrStyle}%`}} >
                                                                                                  {/* status == background-color */}
          </div>
        </div>

    </div>
  )
}


