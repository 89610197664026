import React, { useEffect, useState } from 'react'
import './TimeLine.scss'
import { SelectDownBlue, SelectUpBlue } from '../../../assets/img-svg/all-icons'

export const TimeLine = ({ maxWidthTable, stepInMinuts }) => {
  if (!stepInMinuts) {
    return <TimeLineStep maxWidthTable={maxWidthTable}/>
  } else  {
    return <TimeLineStepInMinutes maxWidthTable={maxWidthTable}/>
  }
}

const TimeLineStepInMinutes = ({maxWidthTable}) => {

  const [times, setTime] = useState(45);
  let stepPerMinut = times * 100 / 60;

  useEffect( () => {
    let maxWidth = document.querySelector(maxWidthTable).scrollWidth
    let timeBox = document.querySelector('.time-box')
    let lineHeight = document.querySelector('.airplane-detail-table-content').clientHeight

    timeBox.style.width = `${maxWidth}px`

    //Progress Line
    function getCoords(elem) {
      let box = elem.getBoundingClientRect();

      return {
        y: box.top + window.pageYOffset,
        x: box.left + window.pageXOffset
      };
    }

    let progress = document.getElementsByClassName('progress_line');
    let Line = document.querySelector(".time-line");
    Line.style.height = `${lineHeight}px`

    Array.prototype.map.call(progress, (progress)=> {
      let progressCoord = getCoords(progress).x
      let lineCoord = getCoords(Line).x

      let styleWidthProgress = lineCoord - progressCoord
      progress.style.width = `${styleWidthProgress}px`
    });

  }, )

  return (
    <div className="time-box">
        <div className="airplane-detail-table-content-row">
          <div className="airplane-detail-table-header-title">
            <div className="airplane-detail-table-header-item">
              <span className="airplane-detail-table-header-item-title"> </span>
            </div>
          </div>
          <div className="airplane-detail-table-header-timeline">
            <div className="time-line-box time-line-box__width" >
              <div className="time-line" style={{ left: `${stepPerMinut}%` }} >
                <SelectDownBlue width="10px" className="time-line-arrow-top step-in-minutes"/>
                <SelectUpBlue width="10px" className="time-line-arrow-bottom"/>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const TimeLineStep = ({maxWidthTable}) => {

  const [times, setTime] = useState("01:00");

  const hours = times.slice(0,-3)
  const stepPerHours = hours * 100 / 24;

  const minutes = times.slice(3)
  let stepPerMinut = minutes * 100 / 60;

  useEffect( () => {
    let maxWidth = document.querySelector(maxWidthTable).scrollWidth
    let timeBox = document.querySelector('.time-box')
    timeBox.style.width = `${maxWidth}px`

    //Progress Line
    function getCoords(elem) {
      let box = elem.getBoundingClientRect();

      return {
        x: box.left + window.pageXOffset
      };
    }

    let progress = document.getElementsByClassName('progress_line');
    let Line = document.querySelector(".time-line")

    Array.prototype.map.call(progress, (progress)=> {
      let progressCoord = getCoords(progress).x
      let lineCoord = getCoords(Line).x
      let styleWidthProgress = lineCoord - progressCoord + 2

      if ( styleWidthProgress >= 118) {
        progress.style.width = `100%`
      } else {
        progress.style.width = `${styleWidthProgress}px`
      }
    });

  }, )

  let styleIndent =  118 / 100 * stepPerMinut

  return (

    <div className="time-box">
      <div className="table-content-columns">
        <div className="table-content-columns title">
          <span className="table-content-columns__width"> </span>
          <span className="table-content-columns__width"> </span>
          <span className="table-content-columns__width"> </span>
        </div>
        <div className="time-line-box" >
          <div className="time-line" style={{left: `${stepPerHours}%`, marginLeft:`${styleIndent}px`}}>
            <SelectDownBlue width="10px" className="time-line-arrow-top"/>
            <SelectUpBlue width="10px" className="time-line-arrow-bottom"/>
          </div>
        </div>
      </div>
    </div>
  )
}
