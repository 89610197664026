import React, { useState } from 'react'
import {Header} from '../../ui/Header/Header'
import {TableContent} from '../../ui/TableContent/TableContent'
import {AirlinesShiftTable} from './Data'
import './ProcessShift.scss'

const ProcessShift = () => {
  const [airlinesItem, setAirlinesItem] = useState(AirlinesShiftTable)
  const updateTableItem = (index, rowId) => {
    setAirlinesItem (prev => {
      return prev.map(item => {
        if (item.id === rowId) {
          item.timeStatus.map (item => {
            if (item.id !== index) {
              item.active = false
            } else {
              item.active = !item.active
            }
            return item
          })
        } else {
          item.timeStatus.map (item => {
            item.active = false
            return item
          })
        }
        return item
      })
    })
  }

  return (
    <>
      <Header />
        <TableContent
          airlinesItem={airlinesItem}
          updateTableItem={updateTableItem}
          extraStyle={"shift"}
          timeLineVisible={true}
        />
    </>
  )
}


export default ProcessShift
