import React from 'react'
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom'
import './Home.scss'
import { Login } from '../Login/Login'

const Home = ({ClickViewLoginPage}) => {

  return (
    <div className="w-100">
      <h2>Welcome to Intell Act!</h2>
      <div className="pages-links">
        <Link to='/' className="link">
          Home
        </Link>
        <Link to='/login' className="link" onClick={() => ClickViewLoginPage() }>
          Login
        </Link>
        <Link to='/processes' className="link">
          Processes
        </Link>
        <Link to='/processes-shift' className="link">
          Process Shift
        </Link>
        <Link to='/flight-tracker' className="link">
          Flight Tracker
        </Link>
      </div>
      <Router>
        <Switch>
          <Route path='/login' component={Login} />
        </Switch>
      </Router>
    </div>
  )
}

export default Home
