import React, { useState } from 'react'
import LogoHeader from '../../ui/LogoHeader/LogoHeader'
import { AirplaneDetails, CamerasItems } from './Data'
import { AirplaneDetail } from '../../ui/AirplaneDetail/AirplaneDetail'
import { Cameras } from './Cameras/Cameras'
import { ArrowLeft } from '../../../assets/img-svg/all-icons'
import { DetailTable } from './DetailTable/DetailTable'
import './FlightTracker.scss'


export const FlightTracker = () => {
  const [ detail, setDetail ] = useState(AirplaneDetails)
  const [ active, setActive ] = useState(true)

  const onActive = () => {
    setActive((prev) => !prev)
  }

  return (
    <div className="flight-tracker">
      <div className="flight-tracker-header">
        <LogoHeader />
        <AirplaneDetail {...detail} detailActive={true} searchVisible={true}/>
      </div>
      <div className="timeline table-max__width pointer " onClick={onActive}>
        { !active ? <span className="timeline-title">Timeline</span> : " "}
        <div className={`timeline-line ${active && "active"}`} >
          <div className="arrow-box">
            { !active ? <ArrowLeft width="10px" height="6px" className="arrow arrow-top" style={{transform: "rotateZ(180deg)"}} /> : <ArrowLeft width="10px" height="6px"  className="arrow arrow-down" style={{transform: "rotateZ(0deg)"}}/> }
            <div className="arrow-box-hint">
              <span>Show timeline</span>
            </div>
          </div>
        </div>
      </div>
      { active &&
      <div className="cameras-box scroll-bar__style">
        {CamerasItems.map( item => {
          return <Cameras key={item.id} {...item}/>
        })}
      </div> }
      <DetailTable active={active}/>
  </div>)
}
