import React from 'react'
import './MyCheckbox.scss'

export const MyCheckbox = ({ id, label, name }) => {
  return (
    <div className="check-wl">
      <input className="check-wl__input" name={name} type="checkbox" id={`check_${id}`} />
      <label className="check-wl__label" htmlFor={`check_${id}`} >
        {label}
      </label>
    </div>
  )
}