import React, { useState } from 'react'
import LogoHeader from '../LogoHeader/LogoHeader'
import FilterHeader from '../FilterHeader/FilterHeader'
import { FilterData } from '../../pages/Process/Data'

const Header = () => {
  const [stepOne, setStepOne] = useState(FilterData)

  const updateFilter = (e, id) => {
    e.stopPropagation()
    setStepOne(prev =>
      prev.map(item => {
        if (item.id !== id) {
          item.isActive = false
          item.display = false
        } else {
          item.isActive = !item.isActive
          item.display = true
        }
        return item
      })
    )
  }

  const updateFilterState = () => {
    setStepOne(prev =>
      prev.map(item => {
        item.display = true
        item.isActive = false
        return item
      })
    )
  }



  return (
    <header className="process-header" >
      <LogoHeader />
      <FilterHeader updateFilter={updateFilter} stepOne={stepOne} updateFilterState={updateFilterState}  />
    </header>
  )
}
export {Header}
