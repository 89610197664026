export const AirplaneDetails = {
  airline: "ELAL",
  flight: "LY337",
  airplaneType: "Boeing 737-800",
  gate: "A10",
  groundHandling: "Ground Team A",
  destination: "Brussels",
  weather: "22 °С",
  AIBT: "10:10:00",
  EOBT: "10:42:00",
  detail:[
    {
      id: 1,
      title: "Chock On",
      value: "0.3",
      max: "0.3",
      active: false
    },
    {
      id: 2,
      title: "Open doors",
      value: "0.2",
      max: "0.2",
      active: false
    },
    {
      id: 3,
      title: "Deplane passengers",
      value: "3.6",
      max: "3.7",
      active: false
    },
    {
      id: 4,
      title : "Service cabin",
      value: "10.1",
      max: "9.6",
      maxPlus: "5",
      active: false
    },
    {
      id: 5,
      title : "Service forward gallery",
      value: "4.1",
      max: "6.7",
      active: true
    },
    {
      id: 6,
      title : "Load forward compartment",
      value: "0",
      max: "7.1",
      active: false
    },
    {
      id: 7,
      title : "Board passengers",
      value: "0",
      max: "9.8",
      active: false
    },
    {
      id: 8,
      title : "Fuel airplane",
      value: "0",
      max: "7.9",
      active: false
    },
    {
      id: 9,
      title : "Chocks off/Pushback",
      value: "0",
      max: "1.0",
      active: false
    }
  ]
}

export const CamerasItems = [
  {
    id: 1,
    title: "Chock on",
    videoUrl: "./video-item.png",
    active:false,
    durationVideo: "00:45"
  },
  {
    id: 2,
    title: "Passengers deplanement",
    videoUrl: "./",
    active:false,
    durationVideo: "00:45"
  },
  {
    id: 3,
    title: "Luggage uploading",
    videoUrl: "./",
    active:true,
    durationVideo: "00:45"
  },
  {
    id: 4,
    title: "Cargo uploading",
    videoUrl: "./",
    active:false,
    durationVideo: "00:45"
  },
  {
    id: 5,
    title: "Service cabin cleaning",
    videoUrl: "./",
    active:false,
    durationVideo: "00:45"
  },
  {
    id: 6,
    title: "Refueling",
    videoUrl: "./",
    active:false,
    durationVideo: "00:45"
  },
  {
    id: 7,
    title: "Refueling",
    videoUrl: "./",
    active:false,
    durationVideo: "00:45"
  },
  {
    id: 8,
    title: "Refueling",
    videoUrl: "./",
    active:false,
    durationVideo: "00:45"
  },
  {
    id: 9,
    title: "Refueling",
    videoUrl: "./",
    active:false,
    durationVideo: "00:45"
  },
  {
    id: 10,
    title: "Refueling",
    videoUrl: "./",
    active:false,
    durationVideo: "00:45"
  },
]
