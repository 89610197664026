import React from 'react'
import {Link} from 'react-router-dom'
import {Logo, ArrowLeft} from "../../../assets/img-svg/all-icons"
import './LogoHeader.scss'

const LogoHeader = () => {
  return (
    <div className="header">
      <Link to='/'>
        <Logo width="120px" className="header__logo"/>
      </Link>

      <div className="header-prev-page">
        <div className="header-arrow-left-box">
          <ArrowLeft width="10px" height="6px" className="header-arrow__left" style={{transform: "rotateZ(90deg)"}}/>
        </div>
        <p className="header-prev-page__label">Process</p>
      </div>
    </div>
  )
}

export default LogoHeader
